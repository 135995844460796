import decisionPurposeFromStore from '@/modules/decision-purpose/store/decision-purpose-form-store';
import decisionPurposeListStore from '@/modules/decision-purpose/store/decision-purpose-list-store';

export default {
    namespaced: true,

    modules: {
        form: decisionPurposeFromStore,
        list: decisionPurposeListStore,
    },
};
